/* eslint-disable no-dupe-keys */
// @ts-ignore
import elmontemeta from '../assets/images/westvalleymeta.jpg'
// @ts-ignore
import bgImage from '../assets/images/treehero.jpg'
import {posts} from "./posts";
import {TreeRemovalContent} from "../Components/TreeRemoval/TreeRemoval";
import {TreeTrimmingContent} from "../Components/TreeTrimming/TreeTrimming";
import {TreePruningContent} from "../Components/TreePruning/TreePruning";
import {StumpGrindingContent} from "../Components/StumpGrinding/StumpGrinding";
import {TreeHealthConsultingContent} from "../Components/TreeHealthConsulting/TreeHealthConsulting";
import {faqs} from './faqs';

import {
    Box,
    Button,
    Container,
    Flex,
    Grid,
    Heading,
    Link,
    List,
    ListItem,
    Stack,
    Text,
    UnorderedList
} from "@chakra-ui/react";
import {BiConversation, BiCut} from "react-icons/bi";
import {
    GiChainsaw,
    GiChoppedSkull,
    GiLandMine,
    GiStumpRegrowth,
    GiTreeBranch,
    GiTreeDoor,
    GiTreeRoots
} from "react-icons/gi";
import {FaConciergeBell, FaRegHandScissors, FaStar, FaTree, FaUserTie} from "react-icons/fa";
import {MdEmergencyShare, MdHealthAndSafety, MdOutlineEmergency, MdOutlineNaturePeople} from "react-icons/md";
import {AiOutlineScissor} from "react-icons/ai";
import {RiEmotionHappyLine} from "react-icons/ri";
import {PiClockCountdownFill} from "react-icons/pi";
import {LandClearingContent} from "../Components/LandClearing/LandClearing";
import {ArboristConsultingContent} from "../Components/ArboristConsulting/ArboristConsulting";
import {EmergencyTreeService} from "../Components/EmergencyTreeService/EmergencyTreeService";
import {TreePlanting} from "../Components/TreePlanting/TreePlanting";

const telLink = 'tel:3856446427';

export const SiteData = {
    hoursOfOperation: "24/7",
    city: 'West Valley City,UT',
    phoneNumber: '(385) 644-6427',
    phoneNumberWithDashes: '385-644-6427',
    telLink,
    buttonCtaText: () => <Flex>Ready for Healthy, Beautiful Trees? Get Your&nbsp;<Text textDecoration={'underline'}
                                                                                       display={'inline'}>Free
        Consultation Today!</Text></Flex>,
    mobileCta: 'Free Consultation Today!',
    keywords: 'Tree Care, services, affordable, professional, experienced, trimming, West Valley City, UT.',
    footerText: 'West Valley City Tree Care, All rights reserved.',
    ogImage: elmontemeta,
    navLinks: [
        {
            title: 'Home',
            href: '/'
        },
        {
            title: 'Services',
            href: '/services/'
        },
        {
            title: 'Blog',
            href: '/blog/'
        },
        {
            title: 'About',
            href: '/about/'
        },
        {
            title: 'Contact Us',
            href: '/contact/'
        },
        {
            title: 'Careers',
            href: '/careers/',
            hideFromTopNav: true
        },
    ],
    signupForm: {
        intro: () => <>We are proud to be West Valley City's top choice for tree care services. We provide a variety
            of tree care solutions for all types and sizes of trees. Count on West Valley City Tree Care for reliable,
            trustworthy service in our local community and the surrounding areas. We're just a <Link
                href={'tel:3856446427'}
                textDecoration={'underline'}>phone
                call away.</Link></>,
        servicesTitle: 'Our Services:',
    },
    homepage: {
        url: 'https://www.westvalleycitytreecare.com',
        metaData: {
            title: 'West Valley City Tree Care: Your Trusted Tree Care Partner | Call 385-644-6427',
            description: 'West Valley City Tree Care: Your trusted partner for reliable tree care solutions. Contact us today for prompt and professional assistance in West Valley City.',
        },
        h1: 'Expert Tree Care Services in West Valley City, UT',
        subtitle: 'Your Reliable Tree Care Partner in West Valley City, Utah',
        servicesTitle: <>Why West Valley City Residents Choose Our&nbsp;<Link href={'/services'}>Tree Care
            Services</Link></>,
        bgImage: bgImage,
        values: [
            {
                id: 0,
                number: "5000+",
                name: "Trees Serviced",
                icon: GiTreeBranch,
            },
            {
                "id": 4,
                "number": <><Flex my={3}><FaStar fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/><FaStar
                    fontSize={'1.9rem'}/><FaStar
                    fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/></Flex></>,
                "name": "Experienced Technicians",
                "icon": FaUserTie
            },
            {
                id: 2,
                number: "98%",
                name: "Customer Satisfaction",
                icon: RiEmotionHappyLine,
            },
            {
                id: 3,
                number: "24h",
                name: "Emergency Response Time",
                icon: PiClockCountdownFill,
            },
        ],
        services:
            [
                {
                    id: '0',
                    text: () => <Text><b>A community business:</b> as a small local business, there is nothing more
                        important to us than our community, and who better to serve a community than its own members?
                        Our certified arborists have a personal stake in the health of your trees and your happiness as
                        a
                        customer</Text>
                },
                {
                    id: '1',
                    text: () => <Text><b>Tailored solutions:</b> whatever your tree care issue, we have the solution.
                        From tree trimming to <a href="https://www.annarbortreeservicepro.com/" target={'_blank'}
                                                 rel={'noreferrer'}>tree removal</a>, at
                        West Valley City Tree Care, you will be able to tell that you are being serviced by an
                        expert-level professional. We invest in the training and certification for all of our arborists.</Text>
                },
                {
                    id: '2',
                    text: () => <Text><b>Your safety and time is our priority:</b> You should never have to sacrifice
                        safety
                        for efficiency. Our hand-selected staff were chosen because of their commitment to our client’s
                        safety and the health of every <a href="https://www.maranalandscaper.com/" target={'_blank'}
                                                          rel={'noreferrer'}>tree</a> in our community. Whether the task
                        be large or small, from planting trees to <a href="https://www.Chicopeetreeservice.com"
                                                                     target={"_blank"}
                                                                     rel={'noreferrer'}>tree removal</a>, we are here to
                        do it safely. Our team is reliable and trustworthy, taking the burden off of you as a homeowner
                        or property manager from all your <a
                            href="https://www.landscapinghermosabeachca.com/" target={'_blank'} rel={'noreferrer'}>tree
                            service</a> needs.</Text>
                },
                {
                    id: '3',
                    text: () => <Text><b>Transparent pricing:</b> we are committed to our reputation as the top <a
                        href="https://www.landscapingburbank.com/" target={'_blank'} rel={'noreferrer'}>tree
                        service</a> provider in West Valley City, UT, and so, we ensure our prices are affordable,
                        without
                        sacrificing the quality of our <a
                            href="https://www.landscapingorangevale.com/" target={'_blank'} rel={'noreferrer'}>tree
                            service</a>. When you work with us, there are no surprise or hidden fees.</Text>
                }
            ],
        content: [
            <>

                <Heading as="h2" size={'md'}>Premier Tree Services in West Valley City, TN</Heading>
                <Text>
                    Explore the finest tree care solutions with West Valley City Tree Service. Nestled in the heart of
                    West Valley City, Tennessee, our seasoned team offers comprehensive tree care solutions including
                    advanced tree shaping, efficient stump removal, and holistic tree wellness, all designed to elevate
                    the health and visual appeal of your arboreal assets.
                    <br/>
                    Our tree shaping services are meticulously crafted to enhance the natural beauty and structural
                    integrity
                    of your trees. Using state-of-the-art techniques and equipment, our arborists skillfully shape trees
                    to
                    promote healthy growth and aesthetics that complement your landscape.
                    <br/>
                    When it comes to stump removal, our team employs efficient methods to eliminate unsightly stumps
                    from
                    your property. Whether you're dealing with large or small stumps, we have the expertise and tools
                    necessary to safely and effectively remove them, restoring the beauty and functionality of your
                    outdoor space.
                    <br/>
                    At West Valley City Tree Service, we prioritize the holistic wellness of your trees. Our
                    comprehensive
                    tree wellness programs encompass regular inspections, disease prevention strategies, and nutrient
                    management plans to ensure that your trees thrive for years to come.
                    <br/>
                    Experience the difference with West Valley City Tree Care – your trusted partner in <a
                    href={"https://sunshinecoasttreeservicespros.com"} target={'_blank'} rel={'noreferrer'}>tree
                    service</a> excellence.
                </Text>

                <Heading as="h2" size={'md'}>Diverse Tree Care Solutions</Heading>
                <Text>
                    Dive into our suite of tailored tree care offerings:
                </Text>
                <UnorderedList>
                    <ListItem>
                        <Link href={"/tree-trimming"}>Tree Shaping</Link>: Precision pruning and shaping, ensuring
                        robust tree health and visual allure. Our tree shaping services are meticulously designed to
                        enhance
                        the natural beauty and structural integrity of your trees. Using advanced techniques and careful
                        attention to detail, our team crafts shapes that promote healthy growth and complement your
                        landscape.
                    </ListItem>
                    <ListItem>
                        <Link href={"/stump-grinding"}>Stump Eradication</Link>: Streamlined and safe stump
                        extraction, maximizing your landscape’s functionality. With our stump grinding services, we
                        utilize
                        specialized equipment to efficiently remove unsightly stumps from your property, restoring both
                        aesthetics and functionality to your outdoor space.
                    </ListItem>
                    <ListItem>
                        <Link href={"/tree-health-consulting"}>Tree Wellness</Link>: Extensive tree health and
                        preservation services for sustained vitality. Our tree wellness programs encompass regular
                        inspections,
                        disease prevention strategies, and nutrient management plans to ensure the long-term health and
                        vitality
                        of your trees.
                    </ListItem>
                    <ListItem>
                        <Link href={"/tree-planting"}>Tree Cultivation</Link>: Skillful tree planting and transfer
                        services, focusing on seamless tree acclimatization. Whether you need new trees planted or
                        existing
                        ones relocated, our team ensures proper care and acclimatization for successful growth and
                        integration
                        into your landscape.
                    </ListItem>
                    <ListItem>
                        <Link href={"/tree-health-consulting"}>Arboreal Consulting</Link>: Expert guidance and
                        insights by certified arborists for strategic tree nurturing. Our certified arborists provide
                        personalized
                        consulting services, offering expert advice on tree health, risk evaluation, and long-term
                        management
                        strategies tailored to your specific landscape and tree needs.
                    </ListItem>
                    <ListItem>
                        <Link href={"/emergency-tree-services"}>Urgent Tree Care</Link>: Rapid response and
                        dependable solutions for critical and <a href={'https://www.1treeemergency.com/'} rel={'noreferrer'} target={"_blank"}>emergency tree care</a> situations. In emergencies, count on us for swift
                        and effective tree care solutions to mitigate risks and restore safety and functionality to your
                        property.
                    </ListItem>
                    <ListItem>
                        <Link href={"/arborist-consultations"}>Arborist Insights</Link>: Specialized consultations
                        addressing tree health, risk evaluation, and management strategies. Our arborist consultations
                        provide valuable insights and recommendations to optimize the health, safety, and beauty of your
                        trees and landscape.
                    </ListItem>
                    <ListItem>
                        <Link href={"/land-clearing"}>Terrain Preparation</Link>: Holistic land clearing services,
                        balancing efficiency with environmental consciousness. Our land clearing services are conducted
                        with careful consideration for environmental impact, utilizing sustainable practices to prepare
                        your terrain for future projects while preserving natural ecosystems.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Our expertise and state-of-the-art tools are at your disposal, ensuring unparalleled tree care that
                    resonates with the unique requirements of the West Valley City community.
                </Text>

                <Heading as="h2" size={'md'}>Eco-Conscious and Quality-Driven Services</Heading>
                <Text>
                    Our commitment at West Valley City Tree Service extends beyond quality service delivery. We are
                    steadfast in our dedication to eco-friendly methodologies, ensuring that every tree we tend to
                    contributes positively to the environmental fabric of your property.
                    <br/>
                    We prioritize sustainable practices in every aspect of our tree care services. From pruning to
                    removal, we employ techniques that minimize environmental impact and promote the long-term health
                    of your trees and surrounding ecosystem.
                    <br/>
                    Our team is trained in the latest arboricultural methods that prioritize tree health and vitality
                    while minimizing waste and harm to the environment. By utilizing eco-friendly equipment and
                    practices, we strive to leave behind a greener, healthier environment with each project we
                    undertake.
                    <br/>
                    At West Valley City Tree Service, we believe in the importance of preserving and nurturing the
                    natural beauty that trees bring to our landscapes. Our commitment to eco-friendly methodologies
                    reflects our dedication to sustainable stewardship of the environment for future generations.
                </Text>

                <Heading as="h2" size={'md'}>Why Opt for West Valley City Tree Service?</Heading>
                <Text>Selecting our services equates to choosing a <a href="https://www.lakecharlestreeservice.com/"
                                                                      target={'_blank'} rel={'noreferrer'}>reputed tree
                    care provider</a> known for:
                </Text>
                <UnorderedList>
                    <ListItem>Broad expertise across various tree care domains.</ListItem>
                    <ListItem>Adherence to sustainable and eco-friendly approaches.</ListItem>
                    <ListItem>Timely, professional, and dependable service execution.</ListItem>
                    <ListItem>Bespoke solutions, catering specifically to your requirements.</ListItem>
                    <ListItem>Competitive pricing without compromising on service quality.</ListItem>
                </UnorderedList>
                <Text>
                    Our mission is to magnify the natural splendor of West Valley City through top-tier tree care and
                    unwavering customer satisfaction.
                </Text>


                <Heading as="h2" size={'md'}>Ready for All Your Tree Care Requirements</Heading>
                <Text>
                    Our proficient team is always at the ready to provide expert and reliable tree care services. Be it
                    routine upkeep or emergency interventions, we stand prepared to assist with exceptional skill and
                    commitment.
                </Text>


                <Heading as="h2" size={'md'}>Connect with West Valley City Tree Service</Heading>
                <Text>
                    Experience the West Valley City Tree Service difference. For professional <a
                    href={"https://www.bestlexingtontreeservice.com/"} target={"_blank"} rel={"noreferrer"}>tree
                    service</a> in West Valley
                    City, TN, <Link href={"tel:4238408746"}>reach out to us at 423-840-8746</Link>. Request a
                    complimentary, bespoke quote today and discover why we are the go-to tree care specialists in the
                    area. Your tree care aspirations are our top priority.
                </Text>

                <Button as={'a'} href={'tel:4238408746'} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                        color={'white'} fontWeight={'bold'}
                        _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                        p={'16px 30px'} borderRadius={0} mt={'2rem'}
                        lineHeight={'26px'}
                        minH={'66px'}
                        width={'100%'}
                        maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for Immediate
                    Assistance</Button>
            </>
        ],
        additionalContent:
            <>
                <Container maxW="container.xl">
                    <Box as="section" pt={10}>
                        <Text mb={4}>
                            When it comes to Professional Tree Care in West Valley area, West Valley Tree Care
                            emerges as a trusted leader. Our dedicated team is committed to providing top-notch tree
                            care services
                            to enhance the health and beauty of your trees. Discover the difference with West Valley
                            Tree Care,
                            where expertise meets a passion for preserving the natural beauty of your landscape.
                        </Text>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Our Tree Care Expertise
                        </Heading>
                        <Text mb={4}>
                            With a profound understanding of local flora and tree health, our team at West Valley Tree
                            Care is
                            dedicated to delivering high-quality tree care services. We employ the latest techniques and
                            arborist
                            practices to ensure the well-being and longevity of your trees.
                        </Text>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Comprehensive Tree Care Services
                        </Heading>
                        <Text mb={4}>
                            Our diverse range of tree care services caters to both residential and commercial
                            properties,
                            promoting the vitality and beauty of your trees throughout the year:
                        </Text>
                        <UnorderedList mb={4}>
                            <ListItem>
                                <strong><Link as={'a'} href="/tree-pruning/">Tree Pruning:</Link></strong> Enhance the
                                structure
                                and health of your trees with our professional pruning services.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/tree-removal/">Tree Removal:</Link></strong> Safely and
                                efficiently
                                remove unwanted or hazardous trees from your property.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/tree-trimming/">Tree Trimming:</Link></strong> Maintain
                                the aesthetic
                                appeal of your trees and promote healthy growth with regular trimming.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/stump-grinding/">Stump Grinding:</Link></strong> Remove
                                unsightly tree
                                stumps to enhance the visual appeal of your landscape.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/tree-health-consulting/">Tree Health
                                    Consulting:</Link></strong> Ensure
                                the well-being of your trees with expert consulting services.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/tree-planting/">Tree Planting:</Link></strong> Add beauty
                                and value to
                                your property with our professional tree planting services.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/arborist-consultations/">Arborist
                                    Consultations:</Link></strong>
                                Consult with our certified arborists for personalized tree care recommendations.
                            </ListItem>
                            <ListItem>
                                <strong><Link as={'a'} href="/land-clearing/">Land Clearing:</Link></strong> Prepare
                                your land for
                                development or landscaping projects with our efficient land clearing services.
                            </ListItem>
                        </UnorderedList>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Our Process - Ensuring Healthy Trees, Every Step of the Way
                        </Heading>
                        <Text mb={4}>
                            At West Valley Tree Care, our process is designed to ensure the health and longevity of your
                            trees
                            while providing you with a seamless experience:
                        </Text>
                        <UnorderedList mb={4}>
                            <ListItem>
                                <strong>Initial Assessment:</strong> We begin with a thorough assessment of your trees'
                                health and
                                specific care needs.
                            </ListItem>
                            <ListItem>
                                <strong>Customized Care Plan:</strong> Based on our assessment, we create a tailored
                                care plan
                                designed to address your trees' unique requirements.
                            </ListItem>
                            <ListItem>
                                <strong>Expert Execution:</strong> Our team of certified arborists implements the care
                                plan with
                                precision and expertise, ensuring optimal results.
                            </ListItem>
                            <ListItem>
                                <strong>Quality Assurance:</strong> We conduct post-service evaluations to ensure that
                                our work meets
                                the highest standards of quality and satisfaction.
                            </ListItem>
                            <ListItem>
                                <strong>Follow-Up Care:</strong> We provide ongoing guidance and support to help you
                                maintain the
                                health and beauty of your trees for years to come.
                            </ListItem>
                        </UnorderedList>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Why Choose West Valley Tree Care?
                        </Heading>
                        <Text mb={4}>
                            In a market filled with tree care options, West Valley Tree Care stands out for several key
                            reasons
                            that resonate with our customers in the West Valley area:
                        </Text>
                        <UnorderedList mb={4}>
                            <ListItem>
                                <strong>Local Expertise:</strong> Our deep understanding of the local ecosystem enables
                                us to
                                provide tailored tree care solutions.
                            </ListItem>
                            <ListItem>
                                <strong>Certified Arborists:</strong> Our team consists of certified arborists dedicated
                                to
                                maintaining the health and beauty of your trees.
                            </ListItem>
                            <ListItem>
                                <strong>Customer-Centric Approach:</strong> Your satisfaction is our priority. We listen
                                to your
                                tree care needs and tailor our services accordingly.
                            </ListItem>
                            <ListItem>
                                <strong>Environmentally Conscious:</strong> We prioritize eco-friendly practices in our
                                tree care
                                services, promoting sustainability.
                            </ListItem>
                            <ListItem>
                                <strong>Comprehensive Services:</strong> From initial tree assessment to post-service
                                guidance, we
                                cover all your tree care needs.
                            </ListItem>
                        </UnorderedList>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Frequently Asked Questions (FAQs) About Tree Care
                        </Heading>
                        <Grid templateColumns={{base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}} gap={6}>
                            {faqs.map(({question, answer}, index) => (
                                <Box
                                    p={5}
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    key={index}
                                >
                                    <Heading as={"h3"} fontSize="xl">{question}</Heading>
                                    <Text mt={4}>{answer}</Text>
                                </Box>
                            ))}
                        </Grid>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Begin Your Journey to Healthy Trees Today
                        </Heading>
                        <Text mb={4}>
                            Ready to give your trees the care they deserve? Contact West Valley Tree Care today to
                            schedule a
                            consultation and take the first step toward vibrant, healthy trees that enhance the beauty
                            and value of
                            your property.
                        </Text>
                        <Button colorScheme="green" size="md" mb={4} as={'a'} href={telLink}>
                            Contact Us Now
                        </Button>
                    </Box>
                </Container>
            </>,
        footerHeading: 'Contact West Valley City Tree Care',
        footerText: () => <>We are committed to earning and keeping your trust – and that is why you can rely on us for
            any tree care needs in West Valley City, UT or our surrounding community. A certified arborist is
            waiting to connect with you today. <Link href={'tel:3856446427'}>Call us</Link> or fill out our <Link
                href={'/contact'}>online contact form</Link> for non-emergency inquiries. West Valley City Tree Care is
            your top solution for tree care needs.</>,
        footerText2: 'Your Tree Health and Satisfaction is Our Priority',
        servicesHeader: 'Our Extensive Range of Tree Services for All Your Arboricultural Needs',
        servicesSubtitle: () => <Text color="muted" fontSize={{base: 'lg', md: 'xl'}}>Explore our comprehensive <Link
            href={'/services'}>suite of services</Link> – from emergency <a
            href={'https://www.johnsoncitytreeservice.com/'} target={"_blank"} rel={'noreferrer'}>tree removal</a> and
            tree health maintenance to <a
                href='https://www.maderalawncare.com/' target='_blank' rel="noreferrer">lawn care service</a>, West
            Valley
            City Tree
            Care is your all-inclusive solution for any tree-related needs.</Text>,
        featuredServices: [
            {
                name: 'Emergency Tree Removal',
                description: 'West Valley City Tree Care provides reliable 24-hour emergency tree removal, ensuring that we\'re always available to handle urgent situations affecting the safety of your property.',
                icon: GiChainsaw,
                path: '/tree-removal/'
            },
            {
                name: 'Tree Trimming and Pruning',
                description:
                    'We offer comprehensive tree trimming and pruning services to maintain the health and aesthetics of your trees, enhancing the beauty of your outdoor spaces.',
                icon: BiCut,
                path: '/tree-trimming/',
                linkTitle: 'Tree Trimming'
            },
            {
                name: 'Stump Grinding and Removal',
                description: 'Specializing in stump grinding and removal, we’re here to safely and efficiently eliminate any unwanted stumps from your yard, improving the usability of your landscape.',
                icon: GiTreeRoots,
                path: '/stump-grinding/',
                linkTitle: 'Stump Grinding'
            },
            {
                name: 'Tree Health and Maintenance',
                description:
                    'Looking to maintain the health of your trees over the long term? We offer a wide range of tree health and maintenance services that cater to the specific needs of your trees.',
                icon: FaTree,
                path: '/tree-health-consulting/',
                linkTitle: 'Tree Maintenance'
            },
            {
                name: 'Tree Planting and Transplanting',
                description: 'Our tree planting and transplanting service ensures the best care and handling of your trees during transit, perfect for those looking to relocate trees without causing them harm.',
                icon: GiTreeDoor,
                path: '/tree-planting/',
                linkTitle: 'Tree Planting and Transplanting'
            },
            {
                name: 'Arborist Consulting Service',
                description:
                    'Need professional advice about your trees? Our certified arborist consulting service can provide you with the necessary knowledge and recommendations to make informed decisions about your tree care.',
                icon: FaConciergeBell,
                path: '/arborist-consultations/',
                linkTitle: 'Tree Health Consulting'
            },
            {
                name: 'Emergency Tree Services',
                description: 'Offering prompt and reliable emergency tree services, we are prepared to address urgent tree care needs caused by storms, damage, or other unforeseen events.',
                icon: MdOutlineEmergency,
                path: '/emergency-tree-services/',
                linkTitle: 'Emergency Tree Services'
            },
            {
                name: 'Arborist Consultations',
                description: 'Gain expert insights and solutions for your trees with our professional arborist consultations, focusing on tree health, risk assessment, and sustainable management.',
                icon: MdOutlineNaturePeople,
                path: '/arborist-consultations/',
                linkTitle: 'Arborist Consultations'
            },
            {
                name: 'Land Clearing',
                description: 'Our comprehensive land clearing services prepare your property for construction, landscaping, or agricultural projects, ensuring an efficient and eco-friendly process.',
                icon: FaRegHandScissors,
                path: '/land-clearing/',
                linkTitle: 'Land Clearing'
            },
        ]
    },
    careers: {
        "url": "https://www.westvalleycitytreecare.com/careers/",
        "metaData": {
            "title": "Join Our Team - Career Opportunities at West Valley City Tree Service, West Valley City, TN",
            "description": "Explore career opportunities in arboriculture with West Valley City Tree Service. Join our team of tree care experts in West Valley City, TN. We're looking for passionate individuals dedicated to quality tree care."
        },
        "h1": "We're Hiring - Become a Part of West Valley City Tree Service",
        "h2": [
            "Why Join West Valley City Tree Service?",
            "Current Openings for Tree Care Specialists",
            "Our Commitment to Employee Development and Satisfaction"
        ],
        "headingText": {
            "whyJoin": "Discover a workplace where your passion for trees and the environment is valued. Join a team committed to providing exceptional tree care services in West Valley City, TN.",
            "openings": "Browse our current job openings for arborists, tree climbers, and grounds maintenance staff. Find a role that aligns with your skills and passion for tree care.",
            "commitment": "At West Valley City Tree Service, we believe in investing in our team's expertise and providing a supportive, dynamic work environment. Your growth in the field of arboriculture is integral to our success."
        },
        "content": [
            <Text>Welcome to the Careers section at <Link href={'/'}>West Valley City Tree Service</Link>, where your
                career
                in arboriculture and tree care can flourish. We're a family of professionals dedicated to the highest
                standards in tree services. Join us in making a positive impact in West Valley City, TN.</Text>,
            <Text>At <Link href={'/'}>West Valley City Tree Service</Link>, we understand that our strength comes from
                our
                people. We are looking for dedicated individuals who share our commitment to exceptional tree care.
                Whether you're an experienced arborist or eager to learn, we offer a workplace where your skills are
                appreciated and nurtured.</Text>,
            <Text>Our employees enjoy a culture that promotes personal and professional growth. We provide comprehensive
                training, competitive compensation, and the opportunity to work with advanced tree care equipment. Our
                goal is to create a safe, rewarding, and enjoyable workplace for everyone on our team.</Text>,
            <Text>As a key part of West Valley City's community, we take pride in serving our neighbors with integrity
                and
                professionalism. Our commitment to service excellence extends to our team members, whom we support with
                a positive work environment, respectful teamwork, and opportunities for career advancement.</Text>,
            <Text>Are you ready to join a leading tree service provider in West Valley City, TN? Check out our current
                job
                openings and find where you fit in our growing team. From skilled tree climbers to customer service
                representatives, there's a place for you at West Valley City Tree Service.</Text>,
            <Text><a href='https://www.westvalleycitytreecare.com/careers/'>Discover your next career
                opportunity</a> with us. Be a part of a team that's dedicated to preserving and enhancing the urban
                canopy. At <Link href={'/'}>West Valley City Tree Service</Link>, we're more than just a company; we're
                a
                community. Apply today and grow your career in tree care.</Text>,
        ]
    },
    about: {
        url: "https://www.westvalleycitytreecare.com/about/",
        metaData: {
            title: "Professional Tree Care in West Valley City | Reliable & Expert Tree Company | West Valley City Tree Service",
            description: "Discover our complete tree care services in West Valley City. From tree pruning to emergency tree removal, our experienced team is available for all your arboricultural needs.",
        },
        h1: "Tree Services in West Valley City, TN",
        h2: [
            "Emergency Tree Services: Available 24/7 for urgent tree care and removal needs.",
            "Experienced Arborists: Our team of certified arborists is trained and equipped to provide expert tree care.",
            "Comprehensive Tree Care: Offering a wide range of services from tree health assessments to stump grinding.",
            "Eco-Friendly Practices: We prioritize the health of your trees and the environment in every service we provide.",
            "Competitive Pricing: Our services are affordably priced, ensuring quality tree care is accessible to all."
        ],
        headingText: {
            commitment: "Dedicated to delivering exceptional tree care services, ensuring the health and beauty of West Valley City's urban forest.",
            team: "Our team at West Valley City Tree Service is composed of passionate arborists and tree care professionals, committed to excellence in every project.",
            services: "West Valley City Tree Service offers a full spectrum of tree care services. From routine pruning and maintenance to emergency tree removals and disease management, our services cater to all your tree care needs. We use the latest techniques and equipment to ensure the health and safety of your trees and property. Whether it's a residential garden or a commercial landscape, our team provides professional, efficient, and eco-friendly services."
        },

        services: [
            {
                id: "0",
                title: "Tree Pruning & Trimming",
                href: "/tree-pruning",
                icon: AiOutlineScissor,
                description: (
                    <Text>
                        Precision pruning and trimming to maintain the health and appearance of your trees, performed by
                        our skilled arborists. Our team utilizes advanced techniques to selectively remove branches,
                        promoting proper growth patterns and enhancing the overall structure of your trees. Whether
                        it's shaping for aesthetics or pruning for health, we ensure that your trees receive the
                        attention they deserve to thrive in their environment.
                    </Text>
                ),
            },
            {
                id: "1",
                title: "Tree Removal",
                href: "/tree-removal",
                icon: GiChoppedSkull,
                description: (
                    <Text>
                        Safe and efficient tree removal services for hazardous or unwanted trees, ensuring your
                        property’s safety and aesthetic appeal. Our experienced team employs industry-leading
                        techniques and equipment to safely dismantle and remove trees of any size or complexity.
                        From strategic planning to meticulous clean-up, we handle every aspect of the tree removal
                        process with professionalism and care, leaving your property pristine and hazard-free.
                    </Text>
                ),
            },
            {
                id: "2",
                title: "Stump Grinding & Removal",
                href: "/stump-grinding",
                icon: GiStumpRegrowth,
                description: (
                    <Text>
                        Comprehensive stump grinding and removal services to clear your landscape and prepare it for new
                        growth or landscaping projects. Our advanced stump grinding equipment ensures efficient
                        removal of tree stumps, eliminating tripping hazards and allowing for seamless landscape
                        renovation or replanting. With our expertise and attention to detail, we transform unsightly
                        stumps into smooth, level surfaces, restoring the beauty and functionality of your outdoor
                        space.
                    </Text>
                ),
            },
            {
                id: "3",
                title: "Emergency Tree Services",
                href: "/emergency-tree-services",
                icon: MdEmergencyShare,
                description: (
                    <Text>
                        Prompt and reliable emergency tree services for storm damage, fallen trees, or urgent tree care
                        needs. Our emergency response team is available 24/7 to address unforeseen tree emergencies,
                        providing swift and effective solutions to mitigate risks and safeguard your property. Whether
                        it's clearing fallen trees after a storm or addressing hazardous branches threatening your
                        home, you can count on us for timely and professional tree care when you need it most.
                    </Text>
                ),
            },
            {
                id: "4",
                title: "Tree Health Assessments",
                href: "/tree-health-assessments",
                icon: MdHealthAndSafety,
                description: (
                    <Text>
                        Expert assessments to diagnose and treat tree diseases and pests, ensuring the longevity and
                        vitality of your trees. Our certified arborists conduct thorough evaluations to identify
                        signs of disease, pest infestation, or other issues impacting your trees' health. With
                        personalized treatment plans and expert recommendations, we address tree health concerns
                        effectively, restoring your trees to optimal health and vitality for years to come.
                    </Text>
                ),
            },
            {
                id: "5",
                title: "Arborist Consultations",
                href: "/arborist-consultations",
                icon: BiConversation,
                description: (
                    <Text>
                        Professional arborist consultations to advise on tree care, planting, and maintenance for
                        healthy and sustainable landscapes. Our experienced arborists provide personalized
                        recommendations tailored to your specific landscape needs, whether it's selecting the
                        right trees for your property, diagnosing tree issues, or developing maintenance plans
                        for long-term tree health. With our guidance and expertise, you can create and maintain
                        beautiful, thriving landscapes that enhance the beauty and value of your property.
                    </Text>
                ),
            },
            {
                id: "6",
                title: "Land Clearing & Lot Preparation",
                href: "/land-clearing",
                icon: GiLandMine,
                description: (
                    <Text>
                        Efficient land clearing services for construction projects, landscaping, and property
                        development, with a focus on environmental responsibility. Our land clearing experts
                        utilize eco-friendly practices and state-of-the-art equipment to clear vegetation,
                        brush, and debris from your property, preparing it for new construction or landscaping
                        endeavors. From small lots to extensive land development projects, we deliver
                        comprehensive land clearing solutions that prioritize sustainability and environmental
                        stewardship, ensuring minimal impact on the surrounding ecosystem.
                    </Text>
                ),
            },
        ],

    },
    testimonials: {
        h1: 'Testimonials',
        heading: 'Find out why our customers love us – from tree trimming to emergency tree removal, West Valley City Tree Service is the top-rated choice for all your tree service needs',
        testimonials: [
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a042581f4e29026704f',
                name: 'Joanna E.',
                logo: undefined,
                title: 'West Valley City, TN',
                quote:
                    '"Last autumn, we were confronted with a considerable obstacle when a colossal tree in our backyard was hit by lightning. We were anxious it would cause damage to our house. West Valley City Tree Service promptly arrived with their crew, swiftly evaluated the situation, and securely removed the tree. Their expertise, professionalism, and effectiveness were beyond comparison."',
            },
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a0425d',
                name: 'Michael W.',
                logo: undefined,
                title: 'West Valley City, TN',
                quote:
                    '"I have relied on West Valley City Tree Service numerous times for tree pruning. Every instance they have impressed me with their knowledge, professionalism, and the quality of their work. They leave my trees looking vibrant and impeccably manicured. Their post-work cleanup is always flawless. For any tree care necessities in West Valley City, I strongly recommend them."',
            },
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a0804d',
                name: 'Rob B.',
                logo: undefined,
                title: 'West Valley City, TN',
                quote:
                    '"I recently used West Valley City Tree Service for a major land clearing project on my property. The level of professionalism and attention to detail was exceptional. They completed the job efficiently, and their concern for environmental impact was evident. They went above and beyond to ensure the land was perfectly prepared for my next project. Highly recommended!"',
            },
        ]
    },
    services: {
        metaData: {
            title: 'Comprehensive Tree Care in West Valley City | West Valley City Tree Care',
            description: 'Discover our comprehensive tree care services in West Valley City. From pruning to tree removal, our reliable team is available for all your needs. Contact us for prompt tree care solutions.',
        },
        h1: 'Your Local Experts for Top-Notch Tree Services in West Valley City, UT',
        h2: 'Tree Care Services We Provide',
        subtext: 'West City Valley Tree Care is equipped to be your sole provider for your arbor care year-round. No matter\n' +
            'the season, our certified arborists are prepared with the tools and training necessary to handle any\n' +
            'commercial or residential tree care service or need. Our services include, but are not limited to, the\n' +
            'following:',
        footerHeading: 'Ready to Elevate Your Landscape?',
        footerText2: () => <>Connect with us at <Link href={'tel:3856446427'}>385-644-6427</Link> or use our online
            contact form for a free quote.
            Experience the West Valley City Tree Care difference today!</>,
        footerText: () => <>Remember, at West Valley City Tree Care, your trees are our top priority. When it comes
            to tree care in West Valley City, UT, look no further. We're only a call away!</>,
        content: [<> <Box>
            <Stack spacing={5}>
                <Heading as="h2" size="xl">West Valley City Tree Service: Expert Tree Care in West Valley City,
                    TN</Heading>
                <Text>At West Valley City Tree Service, we're dedicated to offering superior tree care and maintenance
                    services in West Valley City, TN. Whether you need routine pruning, emergency tree removal, or
                    specialized arborist advice, our team of certified professionals is ready to provide safe,
                    efficient, and environmentally responsible services. We take pride in our commitment to customer
                    satisfaction and our expertise in handling a wide range of tree-related needs.</Text>

                <Heading as="h3" size="lg">Why Choose West Valley City Tree Service?</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Experienced Arborists:</b> Our certified arborists bring extensive knowledge and
                        skill to every project, ensuring the highest standards of tree care.</Text></ListItem>
                    <ListItem><Text><b>Advanced Tree Care Techniques:</b> We use modern, eco-friendly methods and
                        equipment to provide comprehensive tree care that prioritizes the health of your trees and the
                        environment.</Text></ListItem>
                    <ListItem><Text><b>24/7 Emergency Services:</b> Understanding that tree emergencies can occur at any
                        time, we offer around-the-clock services to address urgent tree care needs.</Text></ListItem>
                    <ListItem><Text><b>Competitive Pricing:</b> We believe in offering high-quality services at fair and
                        transparent prices, delivering value to every client.</Text></ListItem>
                    <ListItem><Text><b>Local Expertise:</b> Familiar with the specific tree species and challenges in
                        West Valley City, TN, we provide tailored care for optimal tree health and
                        aesthetics.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Comprehensive Tree Care & Maintenance Services</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Tree Trimming and Pruning:</b> Our precise trimming and pruning services enhance
                        the health and beauty of your trees, contributing to a safer and more appealing
                        landscape.</Text></ListItem>
                    <ListItem><Text><b>Emergency Tree Services:</b> Responding swiftly to emergency situations, we
                        handle storm-damaged trees, fallen limbs, and other urgent scenarios with expertise and
                        care.</Text></ListItem>
                    <ListItem><Text><b>Stump Grinding and Removal:</b> We provide effective stump grinding services to
                        clear your property and facilitate new landscaping opportunities.</Text></ListItem>
                    <ListItem><Text><b>Arborist Consultations:</b> Benefit from our arborist's expert insights on tree
                        health, risk assessment, and proper tree care strategies.</Text></ListItem>
                    <ListItem><Text><b>Land Clearing:</b> Our land clearing services prepare your property for new
                        construction, landscaping, or agricultural use, ensuring an efficient and eco-friendly
                        process.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Tailored Solutions for Your Trees</Heading>
                <Text>We recognize that each tree and property has unique needs. Our customized approach ensures that
                    every aspect of your tree care is handled with attention to detail and tailored to your specific
                    requirements.</Text>

                <Heading as="h3" size="lg">Commitment to Safety and Environmental Care</Heading>
                <Text>At West Valley City Tree Service, your safety and the health of your trees are our top priorities.
                    We're dedicated to providing services that not only meet but exceed industry standards for safety
                    and environmental responsibility.</Text>

                <Heading as="h3" size="lg">Contact Us for Professional Tree Care</Heading>
                <Text>For expert tree care and maintenance services in West Valley City, TN, trust West Valley City Tree
                    Service. Contact us today for professional, reliable, and eco-conscious tree care solutions.</Text>
            </Stack>
        </Box>
        </>],
        services: [
            {
                id: '0',
                text: () => <Text><b><Link href={'/tree-trimming'}>Season-specific Trimming and Pruning</Link>:</b> Our
                    certified arborists are trained to optimize the sunlight and airflow distribution that will keep
                    your trees healthy and disease free year-round – meaning you never have to worry about the beauty,
                    health, or safety of your property.</Text>
            },
            {
                id: '1',
                text: () => <Text><b><Link href={'/stump-grinding'}>Stump Grinding or Removal:</Link></b> Not only are
                    tree stumps a visual detriment to any yard, they
                    can also decrease the usability of your property. We are able to grind or remove tree stumps
                    quickly, and
                    have stump grinding available as needed.</Text>
            },
            {
                id: '2',
                text: () => <Text><b><Link href={'/tree-removal'}>Tree Removal</Link>:</b> Keeping dead, or sick, trees
                    on your property is a huge danger to the safety of both people and trees.</Text>
            },
            {
                id: '3',
                text: () => <Text><b><Link href={'/tree-health-consulting'}>Tree Health Diagnosis and Treatment
                    Plan:</Link></b> Our arborists are capable of diagnosing the problem with your trees quickly and
                    will propose a treatment plan that will meet your needs and the vision you have for your
                    property.</Text>
            },
        ],
        servicesFooter: () => <>Still unsure? Just give us a call at <Link href={'tel:3856446427'}
                                                                           textDecoration={'underline'}>385-644-6427</Link> and
            see for yourself why we are West Valley City, UT’s trusted neighborhood partner in tree care. For a free
            quote, please fill out our online contact form.</>,
        whyh2: 'Ready to elevate your landscape?',
        whySubtext: () => <>When you choose <Link href={'/'}>West Valley City Tree Care</Link> as your neighborhood
            partner in tree service, you can always expect excellence. We have a large team of certified arborists ready
            to meet whatever tree service needs you may have, for commercial or residential properties!</>,
        whyServices: [
            {
                id: '0',
                text: () => <Text><b>Trustworthy Local Professionals:</b> Our certified arborists have the latest
                    training and technology to address your needs efficiently, going above and beyond industry
                    standards.</Text>
            },
            {
                id: '1',
                text: () => <Text><b>We Value You:</b> We respond to all inquiries quickly, and prioritize the beauty of
                    your trees and the health and safety of your property.To put it simply, we value you, and we value
                    your time.</Text>
            },
            {
                id: '2',
                text: () => <Text><b>West Valley City Pride:</b> We are proud to serve as West Valley City’s
                    neighborhood partner in tree care.</Text>
            },
            {
                id: '3',
                text: () => <Text><b>Honesty is Our Policy:</b> You will never be surprised by hidden costs when you
                    choose West Valley City Tree Service. We believe in transparent pricing and honest
                    communication.</Text>
            },
        ]
    },
    contact: {
        url: 'https://www.westvalleycitytreecare.com/contact',
        metaData: {
            title: 'Contact West Valley City Tree Care | Call 385-644-6427',
            description: 'Reach out to West Valley City Tree Care for expert tree care services in West Valley City, UT. We\'re here to ensure the health and beauty of your trees.',
        },
        h1: 'West Valley City Tree Care',
        subtitle: 'Thank you for choosing West Valley City Tree Care. We are West Valley City, UT’s neighborhood partner in tree care. When you choose us, you\n' +
            'know that as a local business, we are committed to keeping your trust. A certified arborist is\n' +
            'waiting to connect with you today.',
        sectionHeading: 'Why Choose West Valley City Tree Care?',
        signUpDescription: 'If you prefer to contact us online, please fill out the form below. We will get back to you as soon as possible.',
        feedbackText: 'Whatever your goal may be – increasing property value, minimizing risk and safety concerns, or\n' +
            'simply contributing to the natural beauty of our community – we are able and excited to help.\n' +
            'Simply fill out our online form or give us a call today.',
        closingText: <Text>Professional and
            affordable tree services just a phone call away</Text>,
        valueProps: [
            {
                title: 'Certified and Insured Professional Arborists:',
                description: 'Our employees are a reflection of us,\n' +
                    'and as such, we put as much into them as we do you. Our employees maintain\n' +
                    'up-to-date certifications and therefore can handle any situation',
            },
            {
                title: 'All-Service Provider:',
                description: 'We are equipped to address any Tree Health threat that may\n' +
                    'occur in the community of West Valley City: all seasons, all properties, all trees, we’ve\n' +
                    'got you covered, and quickly.'
            },
            {
                title: 'Latest Tools and Technology:',
                description: 'By investing in the most up-to-date tools, we ensure the\n' +
                    'safety and satisfaction of our clients and employees. Our equipment allows us to deal\n' +
                    'with the biggest trees with the biggest problems, without wasting any time, or risking\n' +
                    'safety.'
            },
            {
                title: 'Community-Oriented:',
                description: 'As a local business, our highest priority is our reputation with our\n' +
                    'neighbors. We go above and beyond to keep West Valley City trees beautiful, happy,\n' +
                    'and healthy. We are proud to have you in our community!'
            },
            {
                title: 'Environmentally Conscious:',
                description: 'We research and follow the best practices to minimize\n' +
                    'our effect on the environment. Moreover, environmentally-conscious landscaping can\n' +
                    'have a positive impact on property values.'
            },
        ]
    },
    blog: {
        url: 'https://www.westvalleycitytreecare.com/blog',
        metaData: {
            title: 'West Valley City Tree Care: Quality & Professional Tree Services | Blog',
            description: 'Quality tree care services in West Valley City. Expert solutions for tree health and landscape beautification. Contact us for professional tree services at competitive prices.',
        },
        h1: 'West Valley City Tree Care',
        h2: 'Our Blog',
        heading: 'West Valley City Tree Care is dedicated to serving you with professionalism, quality, and efficiency. Whether you require regular tree maintenance or emergency tree services, our team is just a phone call away. Stay tuned to our blog for regular updates. Trust West Valley City Tree Care for all your tree care needs in West Valley City, UT!',
        posts: posts
    },
    treePruning: {
        url: 'https://www.westvalleycitytreecare.com/tree-pruning',
        metaData: {
            title: 'Expert Tree Pruning in West Valley City, UT | West Valley City Tree Care',
            description: 'Maintain the health and beauty of your trees with our professional tree pruning services in West Valley City, UT. Contact West Valley City Tree Care at 385-644-6427 today!'
        },
        h1: 'Tree Pruning Services in West Valley City, UT | West Valley City Tree Care',
        subtitle: 'Trees play a crucial role in enhancing the beauty of your landscape and providing clean air. Regular care and maintenance is essential for their health and longevity. One of the most effective ways to ensure this is through regular pruning. At West Valley City Tree Care, we provide professional tree pruning services that contribute to the health, aesthetics, and safety of your trees.',
        body: <TreePruningContent/>
    },
    treeRemoval: {
        url: 'https://www.westvalleycitytreecare.com/tree-removal',
        metaData: {
            title: 'Safe and Efficient Tree Removal in West Valley City, UT | West Valley City Tree Care',
            description: 'West Valley City Tree Care offers expert tree removal services in West Valley City, UT. Contact us at 385-644-6427 for reliable and efficient tree removal.'
        },
        h1: 'Expert Tree Removal Services in West Valley City, UT | West Valley City Tree Care',
        subtitle: 'Sometimes, due to irreparable damage or potential hazards, tree removal becomes necessary. West Valley City Tree Care specializes in safe, efficient tree removal services, providing peace of mind to homeowners and businesses throughout West Valley City, UT.',
        body: <TreeRemovalContent/>
    },
    treeTrimming: {
        url: 'https://www.overlandparktreecare.com/tree-trimming',
        metaData: {
            title: 'Expert Tree Trimming in Overland Park, KS | Overland Park Tree Care',
            description: 'Overland Park Tree Care provides top-tier tree trimming services, enhancing the health and aesthetics of your trees. Contact us at 913-675-2522 for your tree care needs.'
        },
        h1: 'Professional Tree Trimming Services in Overland Park, KS | Overland Park Tree Care',
        subtitle: 'We provide professional tree trimming services in West Valley City, UT to enhance the health, longevity, and aesthetic appeal of your trees. Trust our certified arborists to ensure your trees are always in the best shape.',
        body: <TreeTrimmingContent/>
    },
    stumpGrinding: {
        url: 'https://www.westvalleycitytreecare.com/stump-grinding',
        metaData: {
            title: 'Effective Stump Grinding in West Valley City, UT | West Valley City Tree Care',
            description: "Eradicate unattractive tree stumps with our efficient stump grinding services in West Valley City, UT. Call West Valley City Tree Care at 385-644-6427 for a complimentary quote!"
        },
        h1: 'Best Stump Grinding Services in West Valley City, UT | West Valley City Tree Care',
        subtitle: 'Whether you recently had a tree removed or have an old tree stump marring your landscape, West Valley City Tree Care has the solution for you. We provide expert and swift stump grinding services in West Valley City, UT, and surrounding regions.',
        body: <StumpGrindingContent/>
    },
    treeHealthConsulting: {
        url: 'https://www.westvalleycitytreecare.com/tree-health-consulting',
        metaData: {
            title: 'Expert Tree Health Consulting in West Valley City, UT | West Valley City Tree Care',
            description: 'West Valley City Tree Care provides professional tree health consulting services, ensuring the health and vitality of your trees. Call us at 385-644-6427 for your tree health needs.'
        },
        h1: 'Comprehensive Tree Health Consulting in West Valley City, UT | West Valley City Tree Care',
        subtitle: 'For a detailed health check-up of your trees, rely on our expert arborists at West Valley City Tree Care. Our comprehensive tree health consulting service is tailored to promote the well-being of your trees.',
        body: <TreeHealthConsultingContent/>
    },
    landClearing: {
        url: 'https://www.westvalleycitytreecare.com/land-clearing',
        metaData: {
            title: 'Professional Land Clearing in West Valley City, TN | West Valley City Tree Service',
            description: "Prepare your property for new projects with our professional land clearing services in West Valley City, TN. Contact West Valley City Tree Service for efficient and eco-friendly solutions!"
        },
        h1: 'Best Land Clearing Services in West Valley City, TN | West Valley City Tree Service',
        subtitle: 'Whether you\'re preparing for construction, landscaping, or agricultural projects, West Valley City Tree Service offers comprehensive land clearing services in West Valley City, TN, and the surrounding areas.',
        body: <LandClearingContent/>
    },
    arboristConsulting: {
        url: 'https://www.westvalleycitytreecare.com/arborist-consultations',
        metaData: {
            title: 'Expert Arborist Consultations in West Valley City, TN | West Valley City Tree Service',
            description: "Benefit from professional arborist consulting for your trees in West Valley City, TN. Get expert advice on tree care, health, and maintenance from West Valley City Tree Service."
        },
        h1: 'Professional Arborist Consultation Services in West Valley City, TN | West Valley City Tree Service',
        subtitle: 'At West Valley City Tree Service, our certified arborists provide expert consulting services to help you understand and manage the trees on your property effectively.',
        body: <ArboristConsultingContent/>
    },
    treePlanting: {
        url: 'https://www.westvalleycitytreecare.com/tree-planting',
        metaData: {
            title: 'Professional Tree Planting in West Valley City, TN | West Valley City Tree Service',
            description: "Enhance your landscape with our expert tree planting services in West Valley City, TN. Choose West Valley City Tree Service for sustainable, healthy tree growth."
        },
        h1: 'Expert Tree Planting Services in West Valley City, TN | West Valley City Tree Service',
        subtitle: 'West Valley City Tree Service offers professional tree planting services, ensuring the health and beauty of your landscape for years to come.',
        body: <TreePlanting/>
    },
    emergencyTreeServices: {
        url: 'https://www.westvalleycitytreecare.com/emergency-tree-services',
        metaData: {
            title: '24/7 Emergency Tree Services in West Valley City, TN | West Valley City Tree Service',
            description: "Immediate response for emergency tree services in West Valley City, TN. Contact West Valley City Tree Service for urgent tree care and removal."
        },
        h1: 'Emergency Tree Services in West Valley City, TN | West Valley City Tree Service',
        subtitle: 'In times of unexpected tree emergencies, West Valley City Tree Service offers fast, reliable, and safe emergency tree care services in West Valley City, TN, and the surrounding areas.',
        body: <EmergencyTreeService/>
    },
    '404': {
        metaData: {
            title: 'Page Not Found - West Valley City Tree Care | Tree Service | 385-644-6427',
            description: 'Oops! The page you\'re looking for could not be found. West Valley City Tree Care offers professional tree care services. Contact us for reliable help.',
            siteName: 'Tree Service in West Valley City, UT | Call 385-644-6427',
        },
        h1: '404 - Oh no, you found a page that\'s missing its roots.',
        subtitle: 'Sorry, the page you are looking for does not exist.',
        p: 'Whether you require tree trimming, stump removal, or any other tree care service, We\'ve got you covered. Call us at 385-644-6427 for your tree care needs.',
    }
}